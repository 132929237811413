<template>
  <v-container fluid>
    <v-card class="fill-height" width="100%">
      <v-card-title class="accent">
        <v-btn :to="{ path: '/usuarios' }" small exact fab text class="mr-2">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-icon color="primary" x-large left>mdi-account-group-outline</v-icon>
        <span class="text-h5 ml-2">{{ usuario.nome }}</span>
      </v-card-title>
      <v-divider></v-divider>

      <v-container fluid>
        <v-row class="fill-height">
          <v-col cols="12" md="12" class="pa-0">
            <v-card flat>
              <v-tabs v-model="tab">
                <v-tab class="justify-start">Dados Gerais</v-tab>
                <v-tab
                  v-if="usuario_original.role_id == 3"
                  class="justify-center"
                  >Leads</v-tab
                >
                <!-- <v-tab class="justify-start">Mídias</v-tab> -->
              </v-tabs>
            </v-card>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-form ref="form" lazy-validation>
                  <div class="d-flex align-center">
                    <h1 class="pa-4 text-h5 font-weight-bold">Dados Gerais</h1>
                    <v-spacer></v-spacer>

                    <v-btn
                      :disabled="!usuario.checkin"
                      :loading="loadingCertificado"
                      color="warning"
                      @click="downloadCertificado"
                      class="mr-6"
                    >
                      <v-icon left>mdi-tray-arrow-down</v-icon>
                      Certificado
                    </v-btn>

                    <v-btn
                      :disabled="!usuario.cadastro"
                      color="primary"
                      @click="printBarcode"
                      class="mr-6"
                    >
                      <v-icon left>mdi-ticket-confirmation</v-icon>
                      Imprimir Crachá
                    </v-btn>

                    <span class="pr-2">Cadastro </span>
                    <span class="mr-4" @click="toggleCadastro">
                      <v-icon
                        :color="
                          usuario.cadastro ? 'green darken-2' : 'grey lighten-1'
                        "
                      >
                        {{
                          usuario.cadastro
                            ? "mdi-checkbox-marked-circle"
                            : "mdi-close-circle"
                        }}
                      </v-icon>
                    </span>
                    <v-divider vertical class="mx-4 my-4"></v-divider>
                    <span class="pr-2">Checkin </span>
                    <span class="mr-4" @click="toggleCheckin">
                      <v-icon
                        :color="
                          usuario.checkin ? 'green darken-2' : 'grey lighten-1'
                        "
                      >
                        {{
                          usuario.checkin
                            ? "mdi-checkbox-marked-circle"
                            : "mdi-close-circle"
                        }}
                      </v-icon>
                    </span>
                  </div>

                  <v-divider class="mb-6"></v-divider>
                  <v-row>
                    <!-- Avatar -->
                    <v-col cols="12" md="2">
                      <v-card flat>
                        <v-img
                          v-if="avatar"
                          :src="avatar"
                          contain
                          class="ml-4"
                        ></v-img>
                        <v-img
                          v-else
                          class="ml-4"
                          :src="require('@/assets/avatar_placeholder.png')"
                          contain
                        ></v-img>
                        <v-btn
                          fab
                          dark
                          x-small
                          absolute
                          bottom
                          right
                          @click="onButtonClick"
                        >
                          <input
                            accept="image/*"
                            type="file"
                            class="d-none"
                            ref="uploader"
                            @change="onFileChanged"
                          />
                          <v-icon>mdi-image</v-icon>
                        </v-btn>
                      </v-card>
                    </v-col>

                    <!-- Dados Usuario -->
                    <v-col cols="12" md="10">
                      <v-row dense class="px-4">
                        <!-- Tipo Usuario -->
                        <v-col class="" cols="12" md="2">
                          <v-combobox
                            v-model="selectedRole"
                            label="Tipo Usuário"
                            :items="roles"
                            dense
                            :rules="formRules"
                            required
                            item-text="descricao"
                            item-value="id"
                          ></v-combobox>
                        </v-col>

                        <!-- Nome -->
                        <v-col cols="12" md="5">
                          <v-text-field
                            v-model="usuario.nome"
                            label="Nome"
                            dense
                          ></v-text-field>
                        </v-col>

                        <!-- Email -->
                        <v-col cols="12" md="5">
                          <v-text-field
                            v-model="usuario.email"
                            label="Email"
                            dense
                            required
                            :rules="formRules"
                          ></v-text-field>
                        </v-col>

                        <!-- Status -->
                        <v-col cols="12" md="2">
                          <v-select
                            v-model="usuario.status"
                            :items="[
                              { text: 'Ativo', value: 1 },
                              { text: 'Inativo', value: 0 },
                              { text: 'Estornado', value: -1 },
                            ]"
                            label="Status"
                            dense
                          ></v-select>
                        </v-col>

                        <!-- Tag -->
                        <v-col cols="12" md="2">
                          <v-text-field
                            v-model.number="usuario.tag"
                            type="number"
                            label="Tag"
                            dense
                          ></v-text-field>
                        </v-col>

                        <!--Documento-->
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="usuario.documento"
                            label="Documento"
                            dense
                          ></v-text-field>
                        </v-col>

                        <!-- Celular -->
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="usuario.celular"
                            label="Celular"
                            dense
                          ></v-text-field>
                        </v-col>

                        <!-- Senha -->
                        <v-col
                          class=""
                          cols="12"
                          :md="usuario_original.role_id == 2 ? '4' : '6'"
                        >
                          <v-text-field
                            v-model="usuario.password"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            name="senha"
                            :type="show ? 'text' : 'password'"
                            @click:append="show = !show"
                            label="Senha"
                            dense
                          ></v-text-field>
                        </v-col>

                        <!-- Confirmar Senha -->
                        <v-col
                          class=""
                          cols="12"
                          :md="usuario_original.role_id == 2 ? '4' : '6'"
                        >
                          <v-text-field
                            v-model="usuario.password_confirmation"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            name="confirmarSenha"
                            :type="show ? 'text' : 'password'"
                            @click:append="show = !show"
                            label="Confirmar Senha"
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col
                          v-if="usuario_original.role_id == 2"
                          cols="12"
                          md="4"
                        >
                          <v-combobox
                            v-model="selectedStaffVendedor"
                            label="Vendedor"
                            :items="staffVendedores"
                            dense
                            item-text="nome"
                            item-value="id"
                            return-object
                          ></v-combobox>
                        </v-col>

                        <v-col cols="12" md="4">
                          <!--Apelido-->
                          <v-text-field
                            v-model="usuario.apelido"
                            label="Apelido"
                            dense
                          ></v-text-field>
                        </v-col>

                        <!--Data de nascimento -->
                        <v-col cols="12" md="4">
                          <DataField
                            label="Data Nascimento"
                            :data_sync.sync="usuario.data_nascimento"
                          />
                        </v-col>

                        <!--Genero -->
                        <v-col cols="12" md="4">
                          <v-select
                            label="Gênero"
                            :items="generos"
                            item-text="descricao"
                            item-value="id"
                            v-model="usuario.genero"
                            dense
                          >
                          </v-select>
                        </v-col>

                        <!--Empreendedor-->
                        <v-col
                          cols="12"
                          md="3"
                          lg="2"
                          class="d-flex flex-column justify-center align-start"
                        >
                          <v-checkbox
                            v-model="usuario.empreendedor"
                            label="Empreendedor"
                          ></v-checkbox>
                          <!-- <span>Empreendedor</span> -->
                          <!-- <v-btn-toggle v-model="usuario.empreendedor">
                            <v-btn :value="true" height="40px"> sim </v-btn>
                            <v-btn :value="false" height="40px"> Não </v-btn>
                          </v-btn-toggle> -->
                        </v-col>

                        <!--XDiamond-->
                        <v-col
                          cols="12"
                          md="3"
                          lg="2"
                          class="d-flex flex-column justify-center align-start"
                        >
                          <v-checkbox
                            v-model="usuario.xdiamond"
                            label="XDiamond"
                          ></v-checkbox>
                        </v-col>

                        <!--VIP-->
                        <v-col
                          cols="12"
                          md="3"
                          lg="2"
                          class="d-flex flex-column justify-center align-start"
                        >
                          <v-checkbox
                            v-model="usuario.vip"
                            label="Vip - X Black"
                          ></v-checkbox>
                        </v-col>
                        <!--Tes-->
                        <v-col
                          cols="12"
                          md="3"
                          lg="2"
                          class="d-flex flex-column justify-center align-start"
                        >
                          <v-checkbox
                            v-model="usuario.tes"
                            label="Tes"
                          ></v-checkbox>
                        </v-col>

                        <!--Maestria-->
                        <v-col
                          cols="12"
                          md="3"
                          lg="2"
                          class="d-flex flex-column justify-center align-start"
                        >
                          <v-checkbox
                            v-model="usuario.maestria"
                            label="Maestria"
                          ></v-checkbox>
                          <!-- <span>Maestria</span>
                          <v-btn-toggle v-model="usuario.maestria">
                            <v-btn :value="true" height="40px"> sim </v-btn>
                            <v-btn :value="false" height="40px"> Não </v-btn>
                          </v-btn-toggle> -->
                        </v-col>

                        <!--Classificação-->
                        <v-col
                          cols="12"
                          md="4"
                          lg="4"
                          class="d-flex flex-column justify-center align-start"
                        >
                          <span class="text-caption">Classificação</span>

                          <v-radio-group
                            class="mt-0"
                            v-model="usuario.classificacao"
                            row
                          >
                            <v-radio label="BRONZE" :value="1"></v-radio>
                            <v-radio label="STANDARD" :value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>

                      <!-- Botao mais informações -->
                      <v-btn
                        @click="maisInformacoes = !maisInformacoes"
                        :disabled="!validForm"
                        color="secondary"
                        text
                        small
                        class="mt-8 mb-4 mx-auto"
                      >
                        <span
                          v-if="maisInformacoes === false"
                          class="text-button"
                        >
                          <v-icon left>mdi-plus</v-icon>
                          Informações Adicionais
                        </span>
                        <span v-else>
                          <v-icon left>mdi-minus</v-icon>
                          Informações Adicionais
                        </span>
                      </v-btn>

                      <!-- Informações Adicionais -->
                      <v-row v-show="maisInformacoes" class="px-4" dense>
                        <v-col
                          cols="12"
                          md="4"
                          v-if="usuario.empreendedor == true"
                        >
                          <v-text-field
                            label="Qual o segmento do seu Negócio?*"
                            style="width: 500px"
                            class="mobile-size"
                            v-model="usuario.ramo_atividade"
                            required
                            :rules="formRules"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          v-if="usuario.empreendedor == true"
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            label="Qual o número de funcionários da Empresa?*"
                            v-model="usuario.qtde_funcionarios"
                            required
                            type="number"
                            :rules="formRules"
                            style="width: 500px"
                            class="mobile-size"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          v-if="usuario.empreendedor == true"
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            label="Por que você resolveu participar do X-Bussines?*"
                            style="width: 500px"
                            class="mobile-size"
                            v-model="usuario.motivo_participacao"
                            required
                            :rules="formRules"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          v-if="usuario.empreendedor == true"
                          cols="12"
                          md="4"
                        >
                          <v-textarea
                            label="Qual a maior dificuldade que você tem hoje para fazer o seu Negócio crescer ou atingir suas metas?*"
                            v-model="usuario.empresa_dificuldade"
                            required
                            rows="12"
                            :rules="formRules"
                            style="width: 500px"
                            class="mobile-size"
                          ></v-textarea>
                        </v-col>

                        <v-col
                          v-if="usuario.empreendedor == true"
                          cols="12"
                          md="4"
                        >
                          <v-textarea
                            label="Qual o seu maior desejo quanto ao seu Negócio? Para onde você quer levar ele? (Detalhe o máximo possível)*"
                            v-model="usuario.desejo_empresa"
                            required
                            rows="12"
                            :rules="formRules"
                            style="width: 500px"
                            class="mobile-size"
                          ></v-textarea>
                        </v-col>

                        <v-col
                          v-if="usuario.empreendedor == true"
                          cols="12"
                          md="4"
                        >
                          <span
                            >Para que eu possa entender e te ajudar melhor no
                            nosso Evento eu preciso saber o tamanho do seu
                            Negócio. Escolha nas alternativas abaixo um
                            intervalo que corresponda ao tamanho do seu
                            Negócio.*</span
                          >
                          <v-radio-group
                            v-model="usuario.renda_anual"
                            mandatory
                          >
                            <v-radio
                              label="Menos de R$ 100 Mil de Faturamento Anual"
                              value="1"
                            ></v-radio>
                            <v-radio
                              label="De R$ 100 mIl a R$ 250 Mil de Faturamento Anual"
                              value="2"
                            ></v-radio>
                            <v-radio
                              label="De R$ 250 mIl a R$ 500 Mil de Faturamento Anual"
                              value="3"
                            ></v-radio>
                            <v-radio
                              label="De R$ 500 mil a R$ 1 Milhão de Faturamento anual"
                              value="4"
                            ></v-radio>
                            <v-radio
                              label="De R$ 1 Milhão a R$ 5 Milhões de Faturamento anual"
                              value="5"
                            ></v-radio>
                            <v-radio
                              label="Maior que R$ 5 Milhões de Faturamento anual"
                              value="6"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>

                        <!--Instagram-->
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="usuario.instagram"
                            label="Instagram"
                            dense
                          ></v-text-field>
                        </v-col>

                        <!--Site-->
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="usuario.site"
                            label="site"
                            dense
                          ></v-text-field>
                        </v-col>

                        <!--Observação-->
                        <v-col cols="12">
                          <v-textarea
                            v-model="usuario.obs"
                            label="Observação"
                            dense
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-8"></v-divider>
                  <v-card-actions class="py-3">
                    <v-btn
                      text
                      :to="{ path: '/usuarios' }"
                      exact
                      color="primary"
                    >
                      <v-icon class="mr-2" left>mdi-arrow-left</v-icon>
                      Voltar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="updateUsuario"
                      color="save"
                      class="mr-1 white--text"
                    >
                      <v-icon left>mdi-content-save</v-icon>
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-tab-item>

              <v-tab-item>
                <Leads
                  v-if="usuario_original.role_id == 3"
                  :user_id="usuario_id"
                />
              </v-tab-item>

              <!-- <v-tab-item class="pa-0">
                <InternFileManager origem="usuarios" :origem_id="usuario_id" />
              </v-tab-item> -->
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { one as fetchUsuario, putUsuario } from "@/api/admin/usuarios.js";
import api from "@/api/axios_service.js";
import { allStaffVendedores } from "@/api/vendedores.js";
import { inputRequired } from "@/plugins/utils.js";
import { all as fetchRoles } from "@/api/admin/roles.js";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import { format } from "date-fns";

const _Last1ByteCode = 0x7e;
const _First2ByteCode = 0xa0;
const _Last2ByteCode = 0xbf;
const _Last3ByteCode = 0xff;
const _3ByteOffset = 0x40;
const _ZplEscapeCharacter = "_";
const _2BytePre = "c2";
const _3BytePre = "c3";

String.prototype.zplHexEncode = function () {
  var hex, i, escHex;
  var result = "";
  for (i = 0; i < this.length; i++) {
    var charCode = this.charCodeAt(i);

    if (charCode <= _Last1ByteCode) result += String.fromCharCode(charCode);
    else if (charCode >= _First2ByteCode && charCode <= _Last2ByteCode) {
      hex = charCode.toString(16);
      escHex = ("0" + hex).slice(-2);
      result += _ZplEscapeCharacter + _2BytePre + _ZplEscapeCharacter + escHex;
    } else if (charCode > _Last2ByteCode && charCode <= _Last3ByteCode) {
      charCode = charCode - _3ByteOffset;
      hex = charCode.toString(16);
      escHex = ("0" + hex).slice(-2);
      result += _ZplEscapeCharacter + _3BytePre + _ZplEscapeCharacter + escHex;
    } else result += "";
  }
  return result;
};

export default {
  name: "Usuario",

  props: ["usuarioId"],

  data() {
    return {
      tab: 0,
      usuario: {},
      usuario_original: {},
      avatar: null,
      avatarUpload: null,
      loading: false,
      formRules: [inputRequired],
      validForm: true,
      maisInformacoes: false,
      loadingCertificado: false,
      show: false,
      roles: [],
      staffVendedores: [],
      loadingRoles: false,
      selectedRole: null,
      selectedStaffVendedor: null,

      generos: [
        {
          id: 0,
          descricao: "Feminino",
        },
        {
          id: 1,
          descricao: "Masculino",
        },
        {
          id: 2,
          descricao: "Prefiro não informar",
        },
      ],
    };
  },

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    Leads: () => import("./components/Leads.vue"),
  },

  computed: {
    usuario_id() {
      return this.$route.params.usuarioId;
    },
  },

  watch: {
    selectedRole() {
      if (this.selectedRole) {
        this.usuario.role_id = this.selectedRole.id;
      }
    },
    selectedStaffVendedor() {
      if (this.selectedStaffVendedor) {
        this.usuario.vendedor_id = this.selectedStaffVendedor.id;
      }
    },

    usuario_id() {
      this.realodUser();
    },
  },

  methods: {
    nome(user_name) {
      let splitName = user_name.split(/ /);
      let firstName = splitName[0] ? splitName[0] : "";
      let lastName = splitName[1] ? splitName[1] : "";
      firstName =
        firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
      lastName =
        lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
      let name = firstName + " " + lastName;
      return name;
    },

    async getUsuario() {
      this.loading = true;
      await fetchUsuario(this.usuario_id)
        .then((response) => {
          this.usuario = response;
          this.usuario_original = { ...response };
          this.avatar = this.usuario.avatar;
          this.usuario.emp;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async getUsuarioStaffs() {
      this.loading = true;
      await allStaffVendedores()
        .then((response) => {
          this.staffVendedores = response;
          this.selectedStaffVendedor = this.staffVendedores.find(
            (vendedor) => vendedor.id === this.usuario.vendedor_id
          );

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    upUsuario(id, usuario) {
      putUsuario(id, usuario)
        .then((response) => {
          if (response.data.success) {
            this.$toast.success("Atualizado com sucesso");
            this.loading = false;
          } else {
            this.$toast.error(response.data.message);
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    updateUsuario() {
      if (
        JSON.stringify(this.usuario) === JSON.stringify(this.usuario_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        const usuario = new FormData();

        if (this.avatarUpload) {
          usuario.append("avatar", this.avatarUpload, this.avatarUpload.name);
        }

        let usuario_2 = {};
        usuario_2.email = this.usuario.email;
        usuario_2.role_id = this.usuario.role_id;
        usuario_2.nome = this.usuario.nome;
        usuario_2.password = this.usuario.password;
        usuario_2.password_confirmation = this.usuario.password_confirmation;
        usuario_2.apelido = this.usuario.apelido;
        usuario_2.celular = this.usuario.celular;
        usuario_2.documento = this.usuario.documento;
        usuario_2.empreendedor = this.usuario.empreendedor;
        usuario_2.tempo_empresa_aberta = this.usuario.tempo_empresa_aberta;
        usuario_2.instagram = this.usuario.instagram;
        usuario_2.site = this.usuario.site;
        usuario_2.status = this.usuario.status;
        usuario_2.obs = this.usuario.obs;
        usuario_2.checkin = this.usuario.checkin;
        usuario_2.vip = this.usuario.vip;
        usuario_2.ramo_atividade = this.usuario.ramo_atividade;
        usuario_2.qtde_funcionarios = this.usuario.qtde_funcionarios;
        usuario_2.motivo_participacao = this.usuario.motivo_participacao;
        usuario_2.empresa_dificuldade = this.usuario.empresa_dificuldade;
        usuario_2.desejo_empresa = this.usuario.desejo_empresa;
        usuario_2.renda_anual = this.usuario.renda_anual;
        usuario_2.data_nascimento = this.usuario.data_nascimento;
        usuario_2.genero = this.usuario.genero;
        usuario_2.vendedor_id = this.usuario.vendedor_id;
        usuario_2.maestria = this.usuario.maestria;
        usuario_2.classificacao = this.usuario.classificacao;
        usuario_2.xdiamond = this.usuario.xdiamond;
        usuario_2.tag = this.usuario.tag;
        usuario_2.tes = this.usuario.tes;

        for (let key in usuario_2) {
          if (
            usuario_2[key] !== null &&
            usuario_2[key] !== undefined &&
            usuario_2[key] !== ""
          ) {
            usuario.append(key, usuario_2[key]);
          } else if (key == "tag") {
            usuario.append(key, "");
          }
        }

        this.upUsuario(this.usuario_id, usuario);
      }
    },

    onButtonClick() {
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];
      this.avatarUpload = file;
      this.usuario.avatar = !this.usuario.avatar ? "avatar" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.avatar = event.target.result;
      };
    },

    getRoles() {
      this.loadingRoles = true;
      return fetchRoles()
        .then((response) => {
          this.roles = response;

          this.selectedRole = this.roles.find(
            (role) => role.id === this.usuario.role_id
          );
          this.loadingRoles = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingRoles = false;
        });
    },

    async printBarcode() {
      try {
        if (!this.usuario.tag) {
          this.$toast.error("Por favor, preencha o campo TAG");
          return;
        }

        // Create a new instance of the object
        const browserPrint = new ZebraBrowserPrintWrapper();

        // Select default printer
        const defaultPrinter = await browserPrint.getDefaultPrinter();

        // Set the printer
        browserPrint.setPrinter(defaultPrinter);

        // Check printer status
        const printerStatus = await browserPrint.checkPrinterStatus();

        // Check if the printer is ready
        if (printerStatus.isReadyToPrint) {
          let id = this.usuario.id;
          let nome = this.nome(this.usuario.nome);
          let apelido = this.nome(
            this.usuario.apelido ? this.usuario.apelido : this.usuario.nome
          );
          let celular = this.usuario.celular;
          let ramo_atividade = this.usuario.ramo_atividade;
          let perfil = this.usuario.perfil;
          let match_persona = this.usuario.match_persona;
          let classificacao = this.usuario.classificacao;
          let tag = this.usuario.tag;

          if (this.usuario.vip) {
            classificacao = "X BLACK";
          } else {
            if (classificacao == 1) {
              classificacao = "BRONZE";
            }
            if (classificacao == 2) {
              classificacao = "STANDARD";
            }
          }

          let qrcode_content = `${id};${nome};${apelido};${celular};${ramo_atividade}`;
          let qrcode_length = qrcode_content.length;

          let qrcode_size = "4";

          // if (qrcode_length < 50) {
          //   qrcode_size = "4";
          // } else if (qrcode_length >= 50 && qrcode_length < 70) {
          //   qrcode_size = "5";
          // } else if (qrcode_length >= 70 && qrcode_length < 80) {
          //   qrcode_size = "4";
          // }

          console.log("Content: " + qrcode_content);
          console.log("Length: " + qrcode_length);
          console.log("Size: " + qrcode_size);
          console.log("Apelido Length: " + apelido.length);
          console.log("Ramo Length: " + ramo_atividade?.length);

          // ZPL script to print a simple barcode
          const zpl = `^XA
                    ^CI28
                    ^FO90,40
                    ^BQN,2,${qrcode_size}
                    ^FDQA,
                    ${qrcode_content}
                    ^FS
                    ^CF0,45
                    ^FO290,63^FH^FD${apelido.zplHexEncode()}^FS
                    ^CF0,30
                    ^FO290,145^FH^FD${
                      ramo_atividade
                        ? ramo_atividade.zplHexEncode().toUpperCase()
                        : ""
                    }^FS
                    ^CF0,20
                    ^FO290,200^FDX BUSINESS 2022 ${match_persona ? "+" : ""}${
            perfil ? perfil : ""
          }${classificacao ? " | " + classificacao : ""} ${
            tag ? " | " + tag : ""
          }^FS
                    ^XZ`;

          console.log(zpl);

          await browserPrint.print(zpl);

          if (!this.usuario.checkin) {
            this.toggleCheckin();
          }
        } else {
          console.log("Error/s", printerStatus.errors);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async downloadCertificado() {
      this.loadingCertificado = true;
      const response = await api.get(
        `/certificado/${this.usuario_id}/download`
      );
      if (response.data.success) {
        const binaryString = window.atob(response.data.result);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: "application/pdf" });
        const blobURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("download", response.data.filename);
        link.href = blobURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.$toast.success("Certificado baixado com sucesso!");
      } else {
        this.$toast.error(response.data.result);
  
      }
      this.loadingCertificado = false;
    },

    toggleCheckin() {
      this.usuario.checkin = this.usuario.checkin
        ? null
        : format(new Date(), "yyyy-MM-dd HH:mm:ss");

      let usuario = {};
      usuario.checkin = this.usuario.checkin;
      this.upUsuario(this.usuario_id, usuario);
    },

    toggleCadastro() {
      this.usuario.cadastro = this.usuario.cadastro
        ? null
        : format(new Date(), "yyyy-MM-dd HH:mm:ss");

      let usuario = {};
      usuario.cadastro = this.usuario.cadastro;
      this.upUsuario(this.usuario_id, usuario);
      this.getUsuario(usuario.id);
    },

    async realodUser() {
      this.loading = true;
      await this.getUsuario();
      await this.getRoles();
      await this.getUsuarioStaffs();
      this.loading = false;
    },
  },

  async mounted() {
    this.loading = true;
    await this.getUsuario();
    await this.getRoles();
    await this.getUsuarioStaffs();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
